<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.21818 6.50003L12.8513 0.866918C13.0496 0.668592 13.0496 0.347045 12.8513 0.148745C12.6529 -0.0495561 12.3314 -0.0495815 12.1331 0.148745L6.49999 5.78186L0.866916 0.148745C0.66859 -0.0495815 0.347044 -0.0495815 0.148744 0.148745C-0.049556 0.347071 -0.0495814 0.668617 0.148744 0.866918L5.78182 6.5L0.148744 12.1331C-0.0495814 12.3314 -0.0495814 12.653 0.148744 12.8513C0.247894 12.9504 0.377869 13 0.507843 13C0.637817 13 0.767766 12.9504 0.866941 12.8513L6.49999 7.2182L12.1331 12.8513C12.2322 12.9504 12.3622 13 12.4922 13C12.6221 13 12.7521 12.9504 12.8513 12.8513C13.0496 12.653 13.0496 12.3314 12.8513 12.1331L7.21818 6.50003V6.50003Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 13 13" },
    width: { type: String, default: "13" },
    height: { type: String, default: "13" },
    color: { type: String },
  },
};
</script>