<template>
  <section class="artwork">
    <!-- <Loader :loading="loading" /> -->
    <div class="artwork__container" v-if="!loading">
      <div class="artwork__container-images">
        <Carousel
          class="artwork__slider"
          :style="{ 'margin-right': artwork.images.length === 1 ? '0' : '' }"
          :navigationEnabled="true"
          :paginationEnabled="false"
          :navigationNextLabel="nextButton"
          :navigationPrevLabel="prevButton"
          :per-page="1"
          :mouseDrag="artwork.images.length > 1"
        >
          <Slide
            class="artwork__slide"
            v-for="(image, index) in artwork.images"
            :key="`image-${index}`"
            @click.native="viewImage(index)"
          >
            <img
              class="artwork__image"
              :src="image"
              :alt="artwork.title"
              tabindex="0"
              decoding="async"
              loading="lazy"
              @keyup.enter="viewImage(index)"
            />
            <BaseButton
              class="artwork__image-zoom button--ml button--outline-black-grey button--ellipse"
              text="Zoom"
            />
          </Slide>
        </Carousel>
        <Copyright class="artwork__copyright" v-if="artwork.copyright">
          {{ `Photo by ${artwork.copyright}` }}
        </Copyright>
      </div>
      <div class="artwork__inner">
        <router-link class="text-title artwork__sub-title" :to="`/creators/${artwork.author.slug}`">
          {{ artwork.author.full_name }}
        </router-link>
        <h1 class="text-description artwork__title" v-if="artwork.is_show_title">
          {{ artwork.title }}
        </h1>
        <div class="artwork__info">
          <p class="text-description artwork__info-text" v-if="artworkSize">
            {{ artworkSize }}
          </p>
          <p class="text-description artwork__info-text" v-if="artwork.sizes.weight">
            Weight - {{ artwork.sizes.weight + "lbs" }}
          </p>
          <p class="text-description artwork__info-text" v-if="artwork.date">
            Date - {{ artwork.date }}
          </p>
          <p class="text-description artwork__info-text" v-if="artwork.location">
            Location - {{ artwork.location }}
          </p>
        </div>
        <p class="text-description artwork__description" v-if="artwork.description">
          "{{ artwork.description }}"
        </p>
        <BaseButton
          class="artwork__button button--lg button--white button--ellipse"
          text="View Creator"
          @click="$router.push({ path: `/creators/${artwork.author.slug}` })"
        />
      </div>
    </div>
    <PopUp
      v-if="artwork.associated"
      class="view-more"
      :show="viewMore"
      :closeCircle="true"
      :closeBold="true"
      :closeLeft="true"
      @hideModal="viewMore = false"
    >
      <div class="view-more__inner">
        <div class="view-more__category" v-if="artwork.associated.editorials.length ? true : false">
          <h6 class="text-description view-more__category-title">Press Releases</h6>
          <div class="view-more__category-inner">
            <router-link
              class="text-description view-more__category-item"
              v-for="(editorial, index) in artwork.associated.editorials"
              :key="`editorial-${index}`"
              :to="`/library/publications/${editorial.slug}`"
            >
              <span class="text-description view-more__category-item-text">
                {{ editorial.title }}
              </span>
            </router-link>
          </div>
        </div>
        <div class="view-more__category" v-if="artwork.associated.books.length ? true : false">
          <h6 class="text-description view-more__category-title">Books</h6>
          <div class="view-more__category-inner">
            <router-link
              class="text-description view-more__category-item"
              v-for="(book, index) in artwork.associated.books"
              :key="`book-${index}`"
              :to="`/library/${book.slug}`"
            >
              <span class="text-description view-more__category-item-text">{{ book.title }}</span>
            </router-link>
          </div>
        </div>
        <div class="view-more__category" v-if="artwork.associated.blogs.length ? true : false">
          <h6 class="text-description view-more__category-title">Blogs</h6>
          <div class="view-more__category-inner">
            <router-link
              class="text-description view-more__category-item"
              v-for="(blog, index) in artwork.associated.blogs"
              :key="`blog-${index}`"
              :to="`/library/blog/${blog.slug}`"
            >
              <span class="text-description view-more__category-item-text">{{ blog.title }}</span>
            </router-link>
          </div>
        </div>
        <div class="view-more__category" v-if="artwork.associated.tags.length ? true : false">
          <h6 class="text-description view-more__category-title">Tags</h6>
          <div class="view-more__category-inner view-more__category-inner_tags">
            <router-link
              class="view-more__category-item view-more__category-item_tag"
              v-for="(tag, index) in artwork.associated.tags"
              :key="`tag-${index}`"
              :to="{ path: '', query: { search_tag: tag.title } }"
            >
              <span class="text-description view-more__category-item-text">{{ tag.title }}</span>
            </router-link>
          </div>
        </div>
        <div class="view-more__category" v-if="artwork.associated.related.length ? true : false">
          <h6 class="text-description view-more__category-title">Related Art</h6>
          <div class="view-more__category-inner view-more__category-inner_tags">
            <router-link
              class="text-description view-more__category-item"
              v-for="(relatedArt, index) in artwork.associated.related"
              :key="`related-art-${index}`"
              :to="`/art/${relatedArt.slug}`"
            >
              <img
                class="view-more__category-item-img"
                :src="relatedArt.search_image"
                :alt="relatedArt.title"
              />
              <span class="text-description view-more__category-item-img-text">
                {{ relatedArt.title }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </PopUp>
    <PopUp
      class="view-more modal--fullscreen"
      :show="showImage"
      :closeCircle="true"
      :closeBold="true"
      @hideModal="showImage = false"
      @click.native="showImage = false"
    >
      <div class="view-more__inner">
        <figure
          ref="zoomer"
          class="view-more__image-container"
          :style="{ backgroundImage: activeImage ? `url(${activeImage})` : '' }"
          @mousemove="zoom($event)"
        >
          <img
            class="view-more__image-container_image"
            :src="activeImage"
            :alt="artwork.title"
            decoding="async"
            loading="lazy"
          />
        </figure>
      </div>
    </PopUp>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Copyright from "@/components/Copyright.vue";
import PopUp from "@/components/PopUp.vue";

export default {
  components: {
    Loader,
    BaseButton,
    Copyright,
    PopUp,
    Carousel,
    Slide,
  },
  data() {
    return {
      loading: true,
      viewMore: false,
      showImage: false,
      activeImage: "",
      pageTitle: "GH - Artwork",
      nextButton: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="68" height="68" viewBox="0 0 68 68">
  <defs>
    <path id="path-1" fill-rule="evenodd" d="M34 0c18.76511083 0 34 15.23488917 34 34 0 18.76511083-15.23488917 34-34 34C15.23488917 68 0 52.76511083 0 34 0 15.23488917 15.23488917 0 34 0z"/>
    <mask id="mask-2" x="0" y="0" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="68" height="68" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#path-1"/>
    </mask>
  </defs>
  <g>
    <g opacity=".805">
      <use fill="rgb(0,0,0)" xlink:href="#path-1"/>
      <use fill-opacity="0" stroke="rgb(255,255,255)" stroke-linecap="butt" stroke-linejoin="miter" stroke-width="2" mask="url(#mask-2)" xlink:href="#path-1"/>
    </g>
    <g>
      <path fill="none" stroke="rgb(255,255,255)" stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" d="M30.29411765 41.67611336l9.4117647-7.77327935"/>
      <path fill="none" stroke="rgb(255,255,255)" stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" d="M30 26l10 7.57894737"/>
    </g>
  </g>
</svg>`,
      prevButton: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="68" height="68" viewBox="0 0 68 68">
  <defs>
    <path id="path-1" fill-rule="evenodd" d="M34 0c18.76511083 0 34 15.23488917 34 34 0 18.76511083-15.23488917 34-34 34C15.23488917 68 0 52.76511083 0 34 0 15.23488917 15.23488917 0 34 0z"/>
    <mask id="mask-2" x="0" y="0" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="68" height="68" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#path-1"/>
    </mask>
  </defs>
  <g transform="rotate(179.999936 34 34)">
    <g opacity=".805">
      <use fill="rgb(0,0,0)" xlink:href="#path-1"/>
      <use fill-opacity="0" stroke="rgb(255,255,255)" stroke-linecap="butt" stroke-linejoin="miter" stroke-width="2" mask="url(#mask-2)" xlink:href="#path-1"/>
    </g>
    <g>
      <path fill="none" stroke="rgb(255,255,255)" stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" d="M30.29411765 41.67611336l9.4117647-7.77327935"/>
      <path fill="none" stroke="rgb(255,255,255)" stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" d="M30 26l10 7.57894737"/>
    </g>
  </g>
</svg>`,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publickArtwork", {
      artwork: "getArtwork",
    }),
    artworkSize() {
      let text = "";
      if (this.artwork.sizes.height) text += `${this.artwork.sizes.height}"`;
      if (this.artwork.sizes.width) {
        if (this.artwork.sizes.height) text += " x ";
        text += `${this.artwork.sizes.width}"`;
      }
      if (this.artwork.sizes.depth) {
        if (this.artwork.sizes.width || this.artwork.sizes.height) text += " x ";
        text += `${this.artwork.sizes.depth}"`;
      }
      if (text) text = "Size - " + text;
      return text;
    },
  },
  async created() {
    this.loading = true;
    const { categoryName, artworkSlug } = this.$route.params;
    await this.apiGetArtwork({ categoryName, artworkSlug });
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Collection",
        to: "/categories",
      },
      {
        title: `${this.artwork.category_name}`,
        to: `/art/${categoryName}`,
      },
      {
        title: `${this.artwork.title}`,
        to: `/art/${categoryName}/${artworkSlug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.pageTitle = `GH - ${this.artwork.title}`;
    this.$emit("updateHead");
    this.loading = false;
  },
  watch: {
    "$route.path": async function () {
      this.viewMore = false;
      this.loading = true;
      const { categoryName, artworkSlug } = this.$route.params;
      await this.apiGetArtwork({ categoryName, artworkSlug });
      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Collection",
          to: "/categories",
        },
        {
          title: `${this.artwork.category_name}`,
          to: `/art/${categoryName}`,
        },
        {
          title: `${this.artwork.title}`,
          to: `/art/${categoryName}/${artworkSlug}`,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);
      this.pageTitle = `GH - ${this.artwork.title}`;
      this.$emit("updateHead");
      this.loading = false;
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publickArtwork", ["apiGetArtwork"]),
    viewImage(index) {
      this.activeImage = this.artwork.originalImages[index];
      this.showImage = true;
    },

    zoom(e) {
      let zoomer = this.$refs.zoomer;
      let offsetX = 0;
      let offsetY = 0;
      e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches[0].pageX);
      e.offsetY ? (offsetY = e.offsetY) : (offsetX = e.touches[0].pageX);
      let x = (offsetX / zoomer.offsetWidth) * 100;
      let y = (offsetY / zoomer.offsetHeight) * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.artwork {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $lightBlack;
  min-width: 100vw;
  flex-grow: 1;
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    padding: 22rem 25px 7rem;
    @media (max-width: $xxs) {
      padding-top: 14rem;
    }
    &-images {
      position: relative;
      padding-bottom: 5rem;
      padding-right: 2.5rem;
      @media (max-width: $xxs) {
        padding-right: 0;
      }
    }
  }
  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    max-height: 700px;
    width: 100%;
    cursor: pointer;
    position: relative;
    &:hover {
      .artwork__image-zoom {
        opacity: 1;
      }
    }
  }
  &__slider {
    max-width: 700px;
    max-height: 700px;
    width: 100%;
    margin-right: 11.5rem;
    @media (max-width: $md) {
      max-width: 600px;
      max-height: 600px;
      margin-right: 0;
    }
    @media (max-width: $sm) {
      max-width: 480px;
      max-height: 480px;
      margin-right: 0;
    }
    &::v-deep {
      .VueCarousel-navigation-button {
        max-width: 72px;
        max-height: 72px;
        &.VueCarousel-navigation-prev {
          @media (max-width: $sm) {
            transform: translateY(-50%) translateX(100%);
          }
        }
        &.VueCarousel-navigation-next {
          @media (max-width: $sm) {
            transform: translateY(-50%) translateX(-100%);
          }
        }

        opacity: 0.9;
        &:hover {
          opacity: 1;
        }
      }
      .VueCarousel-navigation--disabled {
        opacity: 0.5;
        &:hover {
          opacity: 0.5;
        }
      }
      .VueCarousel-inner {
        flex-basis: auto !important;
      }
    }
  }
  &__image {
    max-height: 700px;
    max-width: 700px;
    width: auto;
    height: auto;
    @media (max-width: $md) {
      height: 600px;
      width: 600px;
    }
    @media (max-width: $sm) {
      height: 480px;
      width: 480px;
    }
    @media (max-width: $xs) {
      height: 420px;
      width: calc(100vw - 50px);
    }
    &-zoom {
      position: absolute;
      bottom: 20px;
      right: 20px;
      opacity: 0;
      width: max-content;
    }
  }
  &__inner {
    max-width: 63rem;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    @media (max-width: $xxs) {
      margin-top: 10px;
      min-height: 0;
    }
  }
  &__title {
    line-height: 1.1;
    font-size: 2.9rem;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 15px;
    @media (max-width: $xxs) {
      font-size: 3.7rem;
      line-height: 1.2;
    }
  }
  &__sub-title {
    font-size: 5.5rem;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0;
    text-decoration: none;
  }
  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: $xxs) {
      padding-bottom: 20px;
    }
    &-text {
      margin-right: 2.5rem;
      opacity: 0.7;
      font-size: 1.6rem;
      line-height: 2.75;
      letter-spacing: 0;
      @media (max-width: $xxs) {
        font-size: 1.4rem;
        line-height: 2.14;
      }
    }
  }
  &__description {
    line-height: 1.94;
    flex-grow: 1;
  }
  &__button {
    margin-top: 34px;
    width: max-content;
    min-width: 18rem;
  }
  &__copyright {
    position: absolute;
    left: 0;
    bottom: 0;
    @media (max-width: $xxs) {
    }
  }
}
.view-more {
  &.modal--fullscreen {
    .view-more__inner {
      align-items: center;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @media (max-width: $sm) {
      padding: 0 25px;
      justify-content: flex-start;
    }
  }
  &__category {
    &-inner {
      padding-top: 10px;
      max-height: 25rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @media (max-width: $xxs) {
        max-height: max-content;
      }
      &_tags {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    &-title {
      font-size: 1.9rem;
      color: $lightBlack;
    }
    &-item {
      text-decoration: none;
      &-text {
        font-size: 1.9rem;
        font-weight: 600;
        color: $lightBlack;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &_tag {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        height: 4.5rem;
        padding: 0 25px;
        border: 1.25px solid $lightBlack;
        margin-bottom: 10px;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      &-img {
        width: 195px;
        height: 195px;
        margin-bottom: 5px;
        margin-right: 20px;
        @media (max-width: $xxs) {
          margin-right: 0;
          width: 100vw;
          height: auto;
        }
        &-text {
          display: block;
          font-size: 1.9rem;
          font-weight: 600;
          color: $lightBlack;
          max-width: 195px;
          @media (max-width: $xxs) {
            max-width: 100vw;
          }
        }
      }
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 160% 160%;

    &_image {
      display: block;
      height: 100%;
      transition: opacity 0.5s;

      &:hover {
        opacity: 0;
      }
    }
  }
}
</style>

